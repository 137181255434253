
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export default function Transition(props) {
    const heroImages = props.heroImages;
    const classNames = props.classNames;
    const [currentHeroImage, setHeroImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setHeroImage((prevIndex) => (prevIndex + 1) % heroImages.length);
        }, 5000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, []);

    return(
        <motion.img
            src={heroImages[currentHeroImage]}
            alt={`Slide ${currentHeroImage}`}
            key={currentHeroImage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className={classNames}
        />
    )
}